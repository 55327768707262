<template>
  <div class="PageFooter">
    <div class="container">
      <div class="row pt-5 pb-5">
        <div class="col-sm-12 col-md-3">
          <a href="https://iclubs.ie"><img alt="iClubs logo" src="../assets/logo-alt.png" class="logo"></a>
        </div>
        <div class="col-sm-12 col-md-3">
          <ul>
            <li><a href="https://www.iclubs.ie/">Home</a></li>
            <li><a href="https://www.iclubs.ie/how-it-works">How It Works</a></li>
            <li><a href="https://www.iclubs.ie/features">Features</a></li>
            <li><a href="https://www.iclubs.ie/news">News</a></li>
            <li><a href="https://www.iclubs.ie/sponsors">Sponsors</a></li>
            <li><a href="https://www.iclubs.ie/support">Support</a></li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-3">
          <p>Unit 12D, Six Cross Roads Business Park,<br /> Waterford, Ireland<br /> X91 A303</p>
          <a href="mailto:info@iclubs.ie" class="email">info@iclubs.ie</a>
          <hr>
          <div class="downloads">
            <a href="https://apps.apple.com/ie/app/iclubs/id1644329499" class="downloadable" target="_BLANK"><img src="../assets/download.svg" alt="Download iPhone"></a>
            <a href="https://play.google.com/store/apps/details?id=ie.brightlight.iclubsapp" class="downloadable" target="_BLANK"><img src="../assets/download-android.png" alt="Download Android"></a>
          </div>
        </div>
        <div class="col-sm-12 col-md-3">
          <blockquote>iClubs for your Club</blockquote>
          <a href="https://www.iclubs.ie/c0nt4ct" class="btn btn-primary">Contact Us</a>
          <hr>
          <ul>
            <li><a href="https://www.iclubs.ie/terms-of-service">Terms of Service</a></li>
            <li><a href="https://www.iclubs.ie/privacy-policy">Privacy Policy</a></li>
            <li><a href="https://www.iclubs.ie/cookies-policy">Cookie Policy</a></li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="row pt-3 footnote">
        <div class="col-sm-6">
          &copy; {{ year }} iClubs. Proudly created by <a href="https://www.doublelmedia.ie/">Double L Media</a>. Powerd by <a href="https://www.brightlight.ie/">Brightlight</a>.
        </div>
        <div class="col-sm-6">
          <ul class="inline">
            <li><a href="https://www.facebook.com/iclubs.ie"><i class="fa fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/iclubs.ie/"><i class="fa fa-instagram"></i></a></li>
            <li><a href="https://twitter.com/iclubsapp"><i class="fa fa-twitter"></i></a></li>
            <li><a href="https://www.linkedin.com/company/iclubs-app"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UC8gq96zE9oDpeDD9iq8s1ig"><i class="fa fa-youtube"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.PageFooter {
  padding: 48px 0;

  color: #2c3e50;
}

.PageFooter a:not(.btn) {
  color: #2c3e50
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin: 8px 0
}

ul li a {
  text-decoration: none;
  transition: .3s;
}

.PageFooter a.email {
  color: #35D56C !important
}

.PageFooter a {
  transition: .3s;
}

.PageFooter a:hover {
  color: #35D56C !important
}

ul.inline {
  display: flex;
  justify-content: flex-end;
}

ul.inline li {
  margin: 0 16px;
  font-size: 24px
}

.logo {
  max-width: 250px;
  width: 100%;
}

.downloads {
  display: flex;
  justify-items: space-around;
  align-items: center;
}

.downloads img {
  max-width: 100px;
  margin: 0 auto;
}

.footnote {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

@media (max-width: 767px) {

  .downloads,
  hr {
    display: none;
  }

  .col-md-3 {
    margin-bottom: 30px;
    padding-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid gray !important
  }

  .col-sm-6 {
    width: 100% !important;
    max-width: 100% !important;
    text-align: center;
    margin-bottom: 30px;
  }

  .pb-5 {
    padding-bottom: 0 !important
  }

  .pt-5 {
    padding-top: 0 !important
  }

  ul.inline {
    justify-content: center;
  }
}
</style>
