<template>
  <section class="PageHeader">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <a href="https://iclubs.ie"><img alt="iClubs logo" src="../assets/logo.webp" class="logo"></a>
        </div>
        <!-- <div class="col-sm-12 col-md-2"><span>i<strong>Clubs</strong> For<br />Your Club</span></div> -->
        <div class="col-sm-12 col-md-3 offset-md-2 offset-sm-0 downloads">
          <a href="https://apps.apple.com/ie/app/iclubs/id1644329499" class="downloadable" target="_BLANK">
            <img src="../assets/download.svg" alt="Download iPhone">
          </a>
          <a href="https://play.google.com/store/apps/details?id=ie.brightlight.iclubsapp" class="downloadable" target="_BLANK">
            <img src="../assets/download-android.png" alt="Download Android">
          </a>
        </div>
        <div class="col-sm-12 col-md-2 hideIt">
          <img class="livematchscreen" src="../assets/phone.webp" alt="iClubs Livematch Screen">
        </div>
      </div>
    </div>

    <div class="bar">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1 v-html="msg"></h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 100%;
  max-width: 400px;
  float: left;
}

.livematchscreen {
  max-width: 200px;
  margin-bottom: -100px
}


.bar {
  width: 100%;
  background-color: #35D56C;
  padding: 16px 0px;
  margin-top: -32px;
}


.row {
  align-items: center;
}

.downloadable {
  width: 100%;
  display: block;
  text-align: center;
}

.downloadable img {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0 auto 10px;
}

span {
  color: #35D56C;
  font-weight: 900;
  font-size: 42px;
  letter-spacing: 1px;

}

strong {
  color: #2c3e50;
  font-weight: 900;
}

h1 {
  width: 100%;
  text-align: left;
  font-weight: 800;
  color: #fff;
}

@media (max-width: 1100px) {
  .bar {
    margin-top: 32px;
  }

  .downloads {
    display: flex;
    margin-top: 32px;
    justify-content: space-around;
    width: 40% !important;
  }

  .downloads a {
    display: flex;
    margin: 0 10px;
    align-content: center;

  }

}

@media(max-width:767px) {
  .bar h1 {
    text-align: center !important;
  }

  .downloads {

    width: 100% !important;
  }

  .logo {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
