<template>

  <Header :msg="club.detailsName + ' - ' + club.detailsCounty" v-if="typeof club.detailsName != 'undefined'"></Header>
  <Header v-else></Header>

  <div class="container main" v-if="typeof club.detailsName != 'undefined'">
    <div class="row">

      <div class="col-md-8 offset-md-2 offset-sm-0 col-sm-12" v-if="club.noticeBoard[notice]">
        <div class="row heading">
          <div class="col-sm-6 col-md-6">
            <h2 class="title" v-html="club.noticeBoard[notice].title"></h2>
            <span class="date">Published on: <strong v-html="club.noticeBoard[notice].publishedOn"></strong></span>
          </div>
          <div class="col-sm-6 col-md-6">
            <img :src="club.detailsLogo.sourceUrl" :alt="club.detailsName + ' crest'" v-if="club.detailsLogo" class="crest">
          </div>
        </div>
        <img :src="club.noticeBoard[notice].featuredImage.sourceUrl" :alt="club.detailsName + ' - ' + club.noticeBoard[notice].title" class="featured" v-if="club.noticeBoard[notice].featuredImage">
        <div class="content" v-html="club.noticeBoard[notice].content"></div>
      </div>

      <div class="col-md-8 offset-md-2 offset-sm-0 col-sm-12 notfound" v-else>
        <div class="row">
          <div class="col-sm-12 col-md-6" v-if="!loading">
            <h2>404</h2>
            <h3>Not Found</h3>
            <p>The resource requested could not be found on this server, please try reload page or check if you coppied correct link!</p>
          </div>
          <div class="col-sm-12 col-md-6" v-if="loading">
            <h2>Loading...</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="notfound">
    <div class="container main">
      <div class="row">
        <div class="col-md-8 offset-md-2 offset-sm-0 col-sm-12">
          <div class="row">
            <div class="col-sm-12 col-md-6" v-if="!loading">
              <h2>404</h2>
              <h3>Not Found</h3>
              <p>The resource requested could not be found on this server, please try reload page or check if you coppied correct link!</p>
            </div>
            <div class="col-sm-12 col-md-6" v-if="loading">
              <h2>Loading...</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <i class="fa fa-loading"></i>
  <div class="separator">
    <div class="triangle"></div>
    <div class="container">
      <div class="row">club_id
        <div class="col-md-6  col-sm-12">
          <img src="./assets/logo-white.webp" alt="iClubs Logo White">
          <h3>The new way to generate<br />income for your club.</h3>
          <p>Make more income for your club and provide better than ever value back to your club sponsors. Your club can sell as many advertising spaces on the app as you like and for whatever amount that you decide. It really is that easy and here is the only place you can do it!</p>
          <div class="row mt-5">
            <div class="col-sm-6">
              <a href="https://apps.apple.com/ie/app/iclubs/id1644329499" class="downloadable" target="_BLANK"><img src="./assets/download.svg" alt="Download iPhone"></a>
            </div>
            <div class="col-sm-6">
              <a href="https://play.google.com/store/apps/details?id=ie.brightlight.iclubsapp" class="downloadable" target="_BLANK"><img src="./assets/download-android.png" alt="Download Android"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
  <div class="sticky-bar" v-if="download">
    <a :href="download" class="btn btn-lg btn-primary"> DOWNLOAD NOW</a>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import axios from "axios";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      clubinfo: {},
      notice: 0,
      download: '',
      notfound: false,
      loading: true
    }
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }
  },
  watch: {
    clubinfo: function (val) {
      if (val) {
        if (val.noticeBoard[this.notice])
          if (val.noticeBoard[this.notice].featuredImage) {
            document.querySelector('[property="og:image"]').setAttribute('content', val.noticeBoard[this.notice].featuredImage.sourceUrl);
            document.querySelector('[name="twitter:image"]').setAttribute('content', val.noticeBoard[this.notice].featuredImage.sourceUrl);
          }
        if (val.noticeBoard[this.notice].title) {
          document.querySelector('[property="og:title"]').setAttribute('content', val.noticeBoard[this.notice].title)
          document.querySelector('[name="twitter:title"]').setAttribute('content', val.noticeBoard[this.notice].title);
          document.title = val.noticeBoard[this.notice].title;
        }
        if (val.noticeBoard[this.notice].content) {
          document.querySelector('[name="description"]').setAttribute('content', val.noticeBoard[this.notice].content);
          document.querySelector('[property="og:description"]').setAttribute('content', val.noticeBoard[this.notice].content);
          document.querySelector('[name="twitter:description"]').setAttribute('content', val.noticeBoard[this.notice].content);
        }
      }
    },
  },
  computed: {
    club() {
      return this.clubinfo
    }
  },
  beforeMount() {
    let club_id = window.location.pathname.split('/')[1];
    this.notice = window.location.pathname.split('/')[2];

    if (this.getMobileOperatingSystem() == 'iOS') {
      console.log('iOS Detected')
      // window.location.href = 'https://apps.apple.com/ie/app/iclubs/id1644329499';
      this.download = 'https://apps.apple.com/ie/app/iclubs/id1644329499';
      window.location.href = 'iclubsapp://localhost/#/club/' + club_id + '/notice/' + this.notice;
    } else if (this.getMobileOperatingSystem() == 'Android') {
      console.log('Android Detected')
      this.download = 'https://play.google.com/store/apps/details?id=ie.brightlight.iclubsapp';
      window.location.href = 'iclubsapp://localhost/index.html#/club/' + club_id + '/notice/' + this.notice;
    }

    let options = {
      method: "POST",
      url: "https://admin.iclubs.ie/graphql",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `{club(id: "${club_id}") {  iclub { detailsName detailsCounty detailsLogo { sourceUrl(size: AD_LOGO ) }  noticeBoard { featuredImage{ sourceUrl(size: NOTICE_SINGLE ) } content title publishedOn } }}}`,
      },
    }, self = this;
    (async function () {
      await axios
        .request(options)
        .then(function (response) {
          self.clubinfo = response.data.data.club.iclub;
          self.loading = false;
        })
        .catch(function (error) {
          console.error(error)
          self.notfound = true;
          self.loading = false;
        });
    })();
    setTimeout(() => { self.loading = false; }, 3000);
  },
}
</script>

<style>
.notfound {
  padding: 60px 0
}

.notfound h2 {
  display: block !important;
  width: 100% !important;
  font-size: 240px !important;
  text-align: center !important;
}

.notfound h3 {
  display: block !important;
  width: 100% !important;
  font-size: 80px !important;
  text-align: center !important;
}

.notfound p {
  display: block !important;
  width: 100% !important;
  text-align: center !important;

}

.main {
  margin-top: 40px;
}

.sidebar img {
  width: 100%;
  max-width: 90px;
  margin-bottom: 40px;
}

h1,
h2,
h3,
h4,
span {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

#app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
}

.title {
  margin-bottom: 20px;
  display: block;
}

.separator {
  background-image: url(./assets/pitch.webp);
  background-size: cover;
  background-position: center;
  padding: 250px 0 150px;
  position: relative;
}

.separator .col-md-6 {
  margin: 0 auto;
}

.triangle {
  position: absolute;
  top: -1px;

  width: 0;
  height: 0;
  padding-bottom: 10%;
  padding-left: 100%;
  overflow: hidden;
  z-index: 9;

}

.triangle::after {

  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -5000px;
  border-bottom: 500px solid transparent;
  border-left: 5000px solid #fff;
}

span.date {
  margin-bottom: 20px;
  display: block;
  color: #35D56C;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.separator img {
  width: 200px;
  margin: 0 auto;
  display: block;

}

.sticky-bar {
  display: none !important;
}

.separator h3,
.separator p {
  color: #fff;
  text-align: center;
}

.separator p {
  font-size: 18px;
}

.separator h3 {
  font-size: 44px;
  letter-spacing: 2px;
  line-height: 1.4;
  margin-bottom: 40px;
}

.separator .container {
  position: relative;
  z-index: 9;
}

.separator a {
  text-align: center;
  display: table;
  margin: 0 auto;
}

.separator .overlay::before {
  content: "";
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.btn-primary {
  background-color: #35D56C !important;
  border-color: #35D56C !important;
}

.btn-primary:hover {
  background-color: #2eb85e !important;
  color: #fff !important
}

.downloadable {
  width: 100%;
  display: block;
  text-align: center;
}

.downloadable img {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: 0 auto 10px;
}

.btn-secondary {
  margin-top: 40px !important;
  padding: 8px 32px !important;
  color: #2c3e50 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

img.featured {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 40px;

}

.content a {
  color: #2eb85e;
  text-decoration: none;
}

.content a:hover {
  color: #2eb85e;
  text-decoration: underline;
}

.crest {
  max-width: 100px;
  max-height: 100px;
  float: right;
}

.heading {
  margin-bottom: 32px;
}

@media (max-width: 1100px) {
  .hideIt {
    display: none;
  }

  .notfound h2 {
    font-size: 120px !important;
  }

  .notfound h3 {
    font-size: 70px !important;
  }

  .PageHeader {
    margin-top: 32px;
  }
}

@media (max-width:767px) {
  .main .content * {
    max-width: 100% !important;
  }

  .sticky-bar {
    display: block !important;
    width: 100%;
    position: fixed !important;
    bottom: 0;
    left: 0;
    z-index: 99999;
  }

  .sticky-bar a {
    width: 100%;
    border-radius: 0;
    font-size: 32px;
    font-weight: 900;
    text-decoration: uppercase;
  }

  .notfound h2 {
    font-size: 80px !important;
  }

  .notfound h3 {
    font-size: 50px !important;
  }

  .heading {
    display: flex !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    align-content: center !important;
  }


  .heading>div {

    padding: 0 18px;
    display: block;
    flex: auto;
    width: fit-content;
  }
}
</style>
